import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import emailjs from 'emailjs-com'
import './contactos.css'


export default function Contactos() {

function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('gmail', 'template_s3lf', e.target, 
  'user_3Zue9mTqDov8BymXNzcME')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
}

  return (

    <> 
    <Layout>
     
      <SEO title="Contactos" />
      <div >
     
      <div className="container">
     
      <form className="contact-form" onSubmit={sendEmail}>
            <div className="title">Get in touch with us!</div>
            
            <div className="input-container">
              <input type="text"  name="nome" className="input" placeholder='Name' />
               </div>
             
            <div class="input-container">
              <input type="email" placeholder='Email' name="email" className="input" />
              
            </div>
            
            <div className="input-container">
             <input type="text" name="assunto" placeholder='Subject' className="input" />
            </div>
            
            <div class="input-container">
            <textarea name="messagem" placeholder='Message'  className="input"/>
            </div>

            <input type="submit" value='Send'className="buttn" />
          </form>
          </div>
          </div>
         
         
    </Layout>

    </> 
    
    )
}